const axios = require('axios');
const S = require('string');
const CONSTANTS = require('./constants');

var APIClient = {};

/**
 * Returns the base url for the API
*/
APIClient.getBaseAPIURL = function() {

  return CONSTANTS.PRODUCTION_API_URL;

  if(process.env.NODE_ENV === 'production') {
    return CONSTANTS.PRODUCTION_API_URL;
  } else {
    return CONSTANTS.DEVELOPMENT_API_URL;
  }

};

/**
 * Sets the local storage item
 */
APIClient.setToken = function(text) {

  if(!window.localStorage) return null;
  var storage = window.localStorage;
  storage.setItem(CONSTANTS.APP_KEY + ".token", text);

};

APIClient.getQuery = function (params) {
  if (!params) return ''
  if (Object.keys(params).length <= 0) return ''
  let searchParams = new URLSearchParams(params)
  return '?' + searchParams.toString()
}

/**
 * Returns the token from local storage if logged in
 */
APIClient.getToken = function() {

  if(!window.localStorage) return null;
  var storage = window.localStorage;
  var token = storage.getItem(CONSTANTS.APP_KEY + '.token');
  if(S(token).isEmpty() === true) return null;
  if(token.length < 10) return null;
  return token;

};

/**
 * Do the actual call using the client API
 */
APIClient.call = async function(params, fn) {

  // return the response
  return new Promise(function(resolve, reject) {

    // get the method for call
    let method = (params.method || params.type || 'get').toLowerCase();

    // build up the headers
    let headers = params.headers || {};
    
    // set the query
    let query = params.query || {};

    // get  the token
    let token = APIClient.getToken();

    // set the token if there
    if(S(token).isEmpty() === false) {
      headers['aurorachamp-token'] = token;
    }

    // set the url
    let url = params.url || APIClient.getBaseAPIURL () + params.path;

    // get the count
    let count = 0;

    // loop it
    // eslint-disable-next-line
    for(let key in query) {count++;}

    // set it
    if(count > 0) {
      url = url + APIClient.getQuery(query);
    }

    // get the params
    let options = {

      timeout:    1000 * (params.timeout || 40),
      headers:    headers,
      method:     method,
      url:        url,

    };

    // set it
    if(method === 'post') options.data = params.form;

    // Send a POST request
    axios(options)
    .then((response) => {

      resolve({
        data:       response.data || {},
        ok:         response.status >= 200 && response.status < 300,
        status:     response.status,
        headers:    response.headers,
      });

    })
    .catch((err) => {

      let response = err.response;
      if(response) {
        resolve({
          data:       response.data || {},
          ok:         response.status >= 200 && response.status < 300,
          status:     response.status,
          headers:    response.headers
        });
      } else {
        reject(err);
      }

    });
  });
  
};

// set as default
export default APIClient;