import React, { Component } from 'react';
import './style.scss';
import Header from '../../widgets/header';
import Footer from '../../widgets/footer';
import APIClient from '../../client';
import moment from 'moment';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

class View extends Component {
  constructor(params) {
    super(params);
    this.state = {
      changelogs: [],
    };
  }
  componentWillUnmount = () => {
    if (this.timer) clearInterval(this.timer);
  }
  componentDidMount = async () => {

    // set the databases
    document.title = 'HuskyCodes';
    this.getChangeLogs();

  }

  getChangeLogs = async () => {

    console.log('running');

    // set the call
    let result = null;

    // handle errors
    try {
      result = await APIClient.call({

        path: '/changelogs'

      });
    } catch (err) {
      return;
    }

    // based the result decide if we want to, 
    // pull the report or show not applicable
    this.setState({

      changelogs: result.data || [],

    });
    console.log(this.state.changelogs);

  }

  render() {
    var items = this.state.changelogs;
    return (
      <div>
        <div className="wrapper">
          <div className="changelog">
            <div className="changelog-hero">
              <Header></Header>
              <div className="changelog-hero-meta">
                  <h1 className="changelog-hero-heading">The story of HuskyCodes!</h1>
                  <p className="changelog-hero-slogan">We keep constant log of milestones/events, make for interesting reading :)</p>
                </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="changelog-inner">
                  <div className="timeline-box">
                    <Timeline lineColor={'#F6F7F9'}>
                      {items.map((item) => {
                        return (<TimelineItem
                          key={item.uid}
                          dateInnerStyle={{
                            background: "#414141",
                            color: '#FFF',
                          }}
                          dateText={moment(item.timestamp).format('LL')}
                          className="timeline-item"
                        >
                          <p>{item.text}</p>
                          <div className="timeline-item-tags">
                            {
                              (item.tags.split(',') || []).map((tag) => {
                                return <span>{tag}</span>
                              })
                            }
                          </div>

                        </TimelineItem>);
                      })
                      }
                    </Timeline>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    );
  }
}

export default View;