import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import './burger.scss';
import { slide as Menu } from 'react-burger-menu'

class Header extends Component {
  state = {
    isMobileMenuOpen: false
  }
  render() {

    return (
      <div className="header-container">
        <Menu isOpen={this.state.isMobileMenuOpen} onOpen={() => {
            this.setState({isMobileMenuOpen: true})
          }} onClose={() => {
            this.setState({isMobileMenuOpen: false})
          }} right>
          <Link to="/" className="mobile-link">Home</Link>
          <Link to="/about" className="mobile-link">About Us</Link>
          {/* <Link to="/products" className="mobile-link">Products</Link> */}
          <Link to="/changelog" className="mobile-link">Milestones</Link>
          <Link to="/contact" className="mobile-link">Contact</Link>
        </Menu>
        <div className="header">
        <div className="container">
          <div className="header-wrapper">

            <div className="header-meta">
              <Link to="/">
                <img src="https://cdn.huskycodes.com/text.full.png" />
              </Link>
            </div>
            <div className="header-links">
              <Link to="/" className="header-link">Home</Link>
              <Link to="/about" className="header-link">About Us</Link>
              {/* <Link to="/products" className="header-link">Products</Link> */}
              <Link to="/changelog" className="header-link">Milestones</Link>
              <Link to="/contact" className="header-link">Contact</Link>
            </div>
            <div className="header-social">
              <a href="https://twitter.com/huskycodes" className="header-social-item">
                <span className="fab fa-twitter-square"></span>
              </a>
              <a href="https://www.facebook.com/HuskyCodes-104219868407125/" className="header-social-item">
                <span className="fab fa-facebook-square"></span>
              </a>
              <a href="mailto:info@huskycodes.com" className="header-social-item">
                <span className="fas fa-envelope"></span>
              </a>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        </div>
      </div>

    );
  }
}

export default Header;
